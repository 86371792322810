import { RefCallback, useCallback, useMemo, useRef } from "react";
import { createPopper, Options } from "@popperjs/core";

/**
 * Copied from:https://github.com/tailwindlabs/headlessui/blob/fbaa1ae9da8788910ce8b240851f9779630babcb/packages/playground-react/utils/hooks/use-popper.ts
 * Example implementation to use Popper: https://popper.js.org/
 */
export function usePopper(
  options?: Partial<Options>
): [RefCallback<Element | null>, RefCallback<HTMLElement | null>] {
  const reference = useRef<Element | null>(null);
  const popper = useRef<HTMLElement | null>(null);

  const cleanupCallback = useRef(() => {});
  const instantiatePopper = useCallback(() => {
    if (!reference.current) {
      return;
    }
    if (!popper.current) {
      return;
    }

    // Run cleanup if available.
    if (cleanupCallback.current) {
      cleanupCallback.current();
    }

    cleanupCallback.current = createPopper(
      reference.current,
      popper.current,
      options
    ).destroy;
  }, [reference, popper, cleanupCallback, options]);

  return useMemo(
    () => [
      (referenceDomNode) => {
        reference.current = referenceDomNode;
        instantiatePopper();
      },
      (popperDomNode) => {
        popper.current = popperDomNode;
        instantiatePopper();
      },
    ],
    [reference, popper, instantiatePopper]
  );
}
