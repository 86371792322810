import { mcn } from "@/utils";
import { ChangeEventHandler, PropsWithChildren } from "react";

type AllowedValues = string | number | readonly string[] | undefined;

export type RadioCardButtonProps<T> = PropsWithChildren<{
  id: string;
  name: string;
  className?: string;
  onValueSelected?: (value?: T) => void;
  value: AllowedValues;
  checked?: boolean;
  disabled?: boolean;
}>;

export function RadioCardButton<T>({
  id,
  name,
  className,
  onValueSelected,
  value,
  checked,
  disabled,
  children,
}: RadioCardButtonProps<T>) {
  const onChange: ChangeEventHandler<HTMLInputElement & { value?: T }> = (
    event
  ) => {
    if (onValueSelected) {
      onValueSelected(event?.currentTarget?.value);
    }
  };

  return (
    <label
      htmlFor={id}
      className={mcn(className, "block relative cursor-pointer w-full")}
    >
      <input
        id={id}
        disabled={disabled}
        name={name}
        type="radio"
        onChange={onChange}
        value={value}
        className={mcn("absolute top-0 left-0 opacity-0 ", "peer")}
        checked={checked}
      />
      {/* Custom radio element */}
      <span
        className={mcn(
          /* Base */
          "flex flex-col items-center justify-center",
          "bg-white shadow-elevation-2 p-sm",
          "w-full h-3xl",
          "laptop:h-auto laptop:aspect-square",
          "border-2 rounded-1 border-white",

          // disabled
          disabled && "opacity-30",

          /* Pseudo item dark border - Enables multiple border */
          "after:content['']",
          "after:absolute after:top-0",
          "after:w-full after:h-full",
          "after:border-grey-160 after:border after:rounded-1",
          "after:opacity-0",
          /* Base states */
          "hover:border-solid hover:border-black transition-transform ease-out",
          /* Peer-input States */
          "peer-focus-visible:after:opacity-100",
          "peer-checked:border-yellow-100"
        )}
      >
        {children}
      </span>
    </label>
  );
}
