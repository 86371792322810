import { Assert } from "@/utils/assert";
import { mcn } from "@/utils/mergeClassNames";
import React from "react";

export type SelectUniversityGridProps = {
  children?: React.ReactNode;
  /**
   * @default sm
   */
  size?: "md" | "lg";
};

const SelectUniversityGrid = ({
  children,
  size = "md",
}: SelectUniversityGridProps) => {
  Assert.check(size !== undefined);

  if (size === "lg") {
    return (
      <div
        className={mcn(
          "grid gap-default",
          "grid-cols-1",
          "tablet-sm:grid-cols-1",
          "tablet:grid-cols-2",
          "tablet-lg:grid-cols-2",
          "laptop:grid-cols-3",
          "laptop-lg:grid-cols-3",
          "desktop:grid-cols-4"
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={mcn(
        "grid gap-default",
        "grid-cols-1",
        "tablet-sm:grid-cols-2",
        "tablet:grid-cols-4",
        "tablet-lg:grid-cols-5",
        "laptop:grid-cols-5",
        "laptop-lg:grid-cols-5",
        "desktop:grid-cols-6"
      )}
    >
      {children}
    </div>
  );
};

export default SelectUniversityGrid;
