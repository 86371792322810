import { RadioCardButton, RadioCardButtonProps } from "../RadioCardButton/RadioCardButton";
import { RadioCardButtonOverlayAction, RadioCardButtonOverlayActionProps } from "../RadioCardButton/RadioCardButtonOverlayAction";
import { UniversityKey } from "../SelectUniversity";
import { MemberUniversities, MemberUniversity } from "./memberUniversities";
import {
  getOrganisationLogoPropsFromStaticMemberUniversity,
  OrganisationLogo
} from "./OrganisationLogo";


export type MemberUniversityRadioButtonProps = {
  /**
   * If member universities has be altered this prop
   * can be used.
   */
  memberUniversities?: MemberUniversity[];
  memberUniversity: UniversityKey;
  disabled?: boolean;
  onActionClick?: (key: UniversityKey) => void;
  radioButtonActionProps?: Pick<
    RadioCardButtonOverlayActionProps<UniversityKey>,
    "title" | "onActionClick"
  >;
} & Partial<RadioCardButtonProps<UniversityKey>>;

export const MemberUniversityRadioButton = ({
  memberUniversity: universityKey,
  memberUniversities,
  checked,
  onActionClick,
  radioButtonActionProps,
  ...rest
}: MemberUniversityRadioButtonProps) => {
  const members = Array.from(memberUniversities || MemberUniversities);

  const university =
    universityKey && members.find((x) => x.shortName === universityKey);

  if (!university) throw "No such Member University";

  return (
    <RadioCardButton
      name="radio-member-uni"
      id={`radio-member-uni-${universityKey}`}
      value={universityKey as string}
      className="relative"
      checked={checked}
      {...rest}
    >
      <OrganisationLogo
        {...getOrganisationLogoPropsFromStaticMemberUniversity(university)}
      />
      {checked && (
        <RadioCardButtonOverlayAction
          actionKey={universityKey}
          name={university.fullName}
          {...radioButtonActionProps}
          onActionClick={
            onActionClick || radioButtonActionProps?.onActionClick
          }
        />
      )}
    </RadioCardButton>
  );
};
