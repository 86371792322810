import { mcn } from "@/utils/mergeClassNames";
import Link from "next/link";
import {
  MemberUniversities,
  MemberUniversity,
  MemberUniversityKey,
} from "./memberUniversities";
import { OrganisationLogo } from "./OrganisationLogo";
import { OrganisationBase } from "@/server/backend-api/organisation";
import { Assert } from "@/utils/assert";
import { ImageLoader } from "next/image";
import config from "../../config";

type BaseProps = {
  className?: string;
};

type StaticUniversityProps = BaseProps & {
  type: "static";
  staticMemberUniversity: MemberUniversityKey;
};

type OrganisationProps = BaseProps & {
  type: "organisation";
  university: OrganisationBase;
};

export const OrganisationLinkButton = (
  props: StaticUniversityProps | OrganisationProps
) => {
  let logo: string | MemberUniversity["logo"];
  let name: string;
  let url: string;
  let loader: ImageLoader;
  if (props.type === "static") {
    const staticUniversity = MemberUniversities.find(
      (x) => x.shortName === props.staticMemberUniversity
    );
    Assert.check(staticUniversity !== undefined);
    logo = staticUniversity.logo;
    name = staticUniversity.fullName;
    url = staticUniversity.url;
    loader = (props) => props.src;
  } else {
    logo = props.university.logoImageUri ?? "";
    name = props.university.name ?? "";
    url = props.university.url;
    loader = (props) => `${config.cdn.url}/${props.src}`;
  }

  return (
    <Link
      href={url}
      className={mcn(
        "px-6 py-3 inline-block rounded-1",
        "bg-white shadow-elevation-1 p-sm",

        "w-full h-3xl",
        "laptop:h-auto laptop:aspect-square",
        "border rounded-1 border-white",

        //hover
        "transition-transform ease-out hover:scale-[1.03]",

        //active
        "active:border-yellow-100",

        // focus
        "focus-visible:default-focus",

        props.className
      )}
    >
      <OrganisationLogo name={name} logo={logo} loader={loader} />
    </Link>
  );
};
