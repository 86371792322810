export enum AccountAction {
  Signup = "signup",
  ResetPassword = "resetpassword",
  Claimdump = "claimdump",
  Link = "link",
  Invite = "invite",
}

export const AccountActions = [
  AccountAction.Claimdump,
  AccountAction.ResetPassword,
  AccountAction.Signup,
  AccountAction.Link,
  AccountAction.Invite,
];

export function getAccountActionTargetStartUrl(action?: AccountAction) {
  if (!action || !AccountActions.includes(action)) {
    throw new Error(`No such account action ${action}`);
  }
  return `/${action}`;
}