import {
  MemberUniversityRadioButton,
} from "@/components/OrganisationButton";
import {
  INSAGroupMemberKey,
  INSAGroupMembers,
  MemberUniversities,
  MemberUniversity,
  MemberUniversityKey,
} from "@/components/OrganisationButton/memberUniversities";
import { TooltipContainer, TooltipContentWrapper } from "@/components/Tooltip";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import SelectUniversityGrid, {
  SelectUniversityGridProps,
} from "./SelectUniversityGrid";
import SelectINSAGroup from "./SelectINSAGroup";
import { RadioCardButtonOverlayActionProps } from "./RadioCardButton/RadioCardButtonOverlayAction";

/** ShortName */
export type UniversityKey = MemberUniversityKey | INSAGroupMemberKey;

type Props = {
  selectedUniversity?: UniversityKey;
  universities?: MemberUniversity[];
  onUniversitySelected?: (key?: UniversityKey) => void;
  onActionClick?: (key: UniversityKey) => void;
  className?: string;
  /**
   * Allows for INSA group memebr selection instead of only
   *  "INSA"
   */
  allowGroupExpansion?: boolean;
  radioButtonActionProps?: Pick<
    RadioCardButtonOverlayActionProps<UniversityKey>,
    "title" | "onActionClick"
  >;
  /** Extends items in grid */
  children?: ReactNode;
  gridProps?: SelectUniversityGridProps;
};

function SelectUniversity(props: Props) {
  const router = useRouter();
  const { enableAll } = router.query;
  const isEnablingAll = enableAll === "true";

  const [selectedUniversityShortName, setSelecteUniversityShortName] = useState<
    UniversityKey | undefined
  >();

  const [members, setMembers] = useState<MemberUniversity[]>([]);

  /**
   * Reevaluate members disabled status after render since
   * we are evaluating based on client query parameter.
   */
  useEffect(() => {
    setMembers(() =>
      Array.from(props.universities ?? MemberUniversities).map((x) => ({
        ...x,
        disabled: x.disabled && !isEnablingAll,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.universities]);

  const onUniversitySelected = (key?: UniversityKey) => {
    // Controlled
    if (props.onUniversitySelected) {
      props.onUniversitySelected(key);
    } else {
      setSelecteUniversityShortName(key);
    }
  };

  const enabledMembers = members
    .filter((x) => !x.disabled)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  const disabledMembers = members
    .filter((x) => x.disabled)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  const processedMembers = [...enabledMembers, ...disabledMembers];

  const selectedKey = props.selectedUniversity || selectedUniversityShortName;

  const isINSAGroupOrMemberSelected =
    selectedKey === "insa" ||
    INSAGroupMembers.some((x) => x.shortName === selectedKey);

  return (
    <>
      {!isINSAGroupOrMemberSelected && (
        <SelectUniversityGrid {...props.gridProps}>
          {processedMembers.map((x) => (
            <TooltipContainer
              key={x.shortName}
              tooltip={
                <TooltipContentWrapper pop={true}>
                  {x.disabled ? `${x.fullName} is not available yet.` : null}
                </TooltipContentWrapper>
              }
              options={{
                popperOptions: {
                  placement: "bottom",
                  modifiers: [
                    { name: "offset", options: { offset: [0, -85] } },
                  ],
                },
              }}
            >
              <MemberUniversityRadioButton
                memberUniversities={processedMembers}
                memberUniversity={x.shortName}
                checked={selectedKey === x.shortName}
                onValueSelected={onUniversitySelected}
                disabled={x.disabled}
                onActionClick={props.onActionClick}
                radioButtonActionProps={props.radioButtonActionProps}
              />
            </TooltipContainer>
          ))}
          {props.children}
        </SelectUniversityGrid>
      )}

      {props.allowGroupExpansion && isINSAGroupOrMemberSelected && (
        <SelectINSAGroup
          selectedKey={selectedKey}
          onUniversityShortNameSelected={onUniversitySelected}
          radioButtonActionProps={props.radioButtonActionProps}
          onActionClick={
            props.onActionClick || props.radioButtonActionProps?.onActionClick
          }
        />
      )}
    </>
  );
}

export default SelectUniversity;
