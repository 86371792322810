import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { usePopper } from "@/hooks/usePopper";
import { Options as PopperOptions } from "@popperjs/core";
import { BodyPortal } from "@/components/Misc/BodyPortal";
import { mcn } from "../utils";
import { IRenderFunction } from "@/types/IRenderFunction";

type TooltipContentWrapperProps = PropsWithChildren<{
  /**
   * Elevates surface with shadow
   */
  pop?: boolean;
  onRenderContent?: IRenderFunction<TooltipContentWrapperProps>;
}>;

export const TooltipContentWrapper = (props: TooltipContentWrapperProps) =>
  props.children ? (
    <div
      className={mcn(
        "bg-white px-2xs py-3xs text-style-body2 rounded-1",
        props.pop && "shadow-elevation-1"
      )}
    >
      {props.onRenderContent ? props.onRenderContent(props) : props.children}
    </div>
  ) : null;

interface Options {
  delay?: number;
  popperOptions?: Partial<PopperOptions>;
}

interface Props {
  options?: Options;
  tooltip: JSX.Element;
}

export function TooltipContainer(props: PropsWithChildren<Props>) {
  const { options, children, tooltip } = props;
  const [containerRef, popperRef] = usePopper(options?.popperOptions);
  const [hoverTimeoutId, setHoverTimeoutId] = useState<number | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const delayMs = options?.delay ?? 500;

  // To avoid setState after dismount.
  const dismounted = useRef<boolean>(false);

  useEffect(() => {
    dismounted.current = false;
    return () => {
      dismounted.current = true;
    };
  }, []);

  function clearHoverTimeout() {
    if (hoverTimeoutId && !dismounted.current) {
      window.clearTimeout(hoverTimeoutId);
      setHoverTimeoutId(null);
    }
  }

  return (
    <>
      {showTooltip && (
        <BodyPortal>
          <div
            className="z-50 pointer-events-none popper-tooltip"
            ref={popperRef}
          >
            {tooltip}
          </div>
        </BodyPortal>
      )}
      <div
        ref={containerRef}
        onPointerEnter={() => {
          const id = window.setTimeout(() => {
            if (!dismounted.current) {
              setShowTooltip(true);
            }
          }, delayMs);
          setHoverTimeoutId(id);
        }}
        onPointerLeave={() => {
          setShowTooltip(false);
          clearHoverTimeout();
        }}
      >
        {children}
      </div>
    </>
  );
}

interface TooltipProps {
  text: string;
  title?: string;
}

export function Tooltip(props: PropsWithChildren<TooltipProps>) {
  function InnerTooltip() {
    return (
      <>
        <div className={"popper-tooltip-arrow"}></div>
        <div className="p-4 bg-white border border-grey-20 text-style-body2 rounded-2 shadow-elevation-3">
          {props.text}
        </div>
      </>
    );
  }

  return (
    <TooltipContainer
      tooltip={<InnerTooltip />}
      options={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "arrow",
              options: {
                element: ".popper-tooltip-arrow",
              },
            },
          ],
        },
      }}
    >
      {props.children}
    </TooltipContainer>
  );
}
