import { mcn } from "@/utils/index";
import IconActionArrowRight from "../Icons/generated/IconActionArrowRight";

export type RadioCardButtonOverlayActionProps<K> = {
  actionKey: K;
  title?: string;
  name: string;
  onActionClick?: (key: K) => void;
};

export function RadioCardButtonOverlayAction<T>(
  props: RadioCardButtonOverlayActionProps<T>
) {
  return (
    <button
      onClick={() => {
        if (props.onActionClick) {
          props.onActionClick(props.actionKey);
        }
      }}
      className={mcn(
        "absolute bottom-0 left-0 z-10",
        "flex flex-row w-full p-xs",
        "bg-yellow-100 hover:bg-yellow-120"
      )}
    >
      <div className="flex flex-col items-start flex-1">
        <span className="text-style-caption mb-2xs">
          {props.title ?? "Continue with"}
        </span>
        <span className="text-style-subtitle2 text-start">
          {props.name}
        </span>
      </div>
      <IconActionArrowRight className="min-w-fit" />
    </button>
  );
}
