import {
  INSAGroupMemberKey,
  INSAGroupMembers
} from "@/components/OrganisationButton/memberUniversities";
import {
  getOrganisationLogoPropsFromStaticMemberUniversity,
  OrganisationLogo
} from "@/components/OrganisationButton/OrganisationLogo";
import { RadioCardButton } from "@/components/RadioCardButton/RadioCardButton";
import { TooltipContainer, TooltipContentWrapper } from "@/components/Tooltip";
import { useRouter } from "next/router";
import { IconActionArrowRight } from "./Icons";
import { RadioCardButtonOverlayAction, RadioCardButtonOverlayActionProps } from "./RadioCardButton/RadioCardButtonOverlayAction";
import { UniversityKey } from "./SelectUniversity";
import SelectUniversityGrid from "./SelectUniversityGrid";

type Props = {
  onUniversityShortNameSelected: (key?: UniversityKey) => void;
  onActionClick?: (key: INSAGroupMemberKey) => void
  selectedKey?: UniversityKey;
  className?: string;
  radioButtonActionProps?: Pick<RadioCardButtonOverlayActionProps<INSAGroupMemberKey>, "title" | "onActionClick">;
};

function SelectINSAGroup(props: Props) {
  const router = useRouter();
  const { enableAll } = router.query;

  const isEnablingAll = enableAll === "true";

  const members = Array.from(INSAGroupMembers).map((x) => ({
    ...x,
    disabled: x.disabled && !isEnablingAll,
  }));

  const enabledMembers = members
    .filter((x) => !x.disabled)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));
  const disabledMembers = members
    .filter((x) => x.disabled)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  const processedMembers = [...enabledMembers, ...disabledMembers];

  return (
    <SelectUniversityGrid>
      <RadioCardButton
        name="radio-member-insa-back"
        id={`radio-member-insa-back`}
        value={undefined}
        onValueSelected={props.onUniversityShortNameSelected}
        className="text-style-headline2"
      >
        <IconActionArrowRight className="rotate-180 text-black" />
      </RadioCardButton>
      {processedMembers.map((x) => (
        <TooltipContainer
          key={`radio-member-insa-${x.shortName}`}
          tooltip={
            <TooltipContentWrapper pop={true}>
              {x.disabled ? `${x.fullName} is not available yet.` : null}
            </TooltipContentWrapper>
          }
          options={{
            popperOptions: {
              placement: "bottom",
              modifiers: [{ name: "offset", options: { offset: [0, -85] } }],
            },
          }}
        >
          <RadioCardButton
            name="radio-member-insa"
            id={`radio-member-insa-${x.shortName}`}
            value={x.shortName}
            checked={props.selectedKey === x.shortName}
            onValueSelected={props.onUniversityShortNameSelected}
            disabled={x.disabled}
            className="relative"
          >
            <OrganisationLogo
              {...getOrganisationLogoPropsFromStaticMemberUniversity(x)}
            />
            {props.selectedKey === x.shortName && (
              <RadioCardButtonOverlayAction
                actionKey={props.selectedKey}
                name={x.fullName}
                {...props.radioButtonActionProps}
                onActionClick={props.onActionClick || props.radioButtonActionProps?.onActionClick}
              />
            )}
          </RadioCardButton>
        </TooltipContainer>
      ))}
    </SelectUniversityGrid>
  );
}

export default SelectINSAGroup;
